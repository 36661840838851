.modal-wrapper {
  width: 543px;
  display: flex;
  background-color: #000000e5;
  // background: rgba(0, 0, 0, 0.6);
  // backdrop-filter: blur(24px);
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  overflow-y: auto;
  max-height: 95vh;
}

.deposit__withdraw__modal__btn {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.modal-close-btn {
  cursor: pointer;
  position: absolute;
  top: 30px;
  z-index: 999;
  right: 25px;
}

.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5;
}

.modal-content {
  margin-top: 10%;
  .modal-title {
    color: var(--primary-color);
    line-height: 0px;
    text-align: center;
  }
  .modal-seperator {
    display: block;
    margin: 16px auto;
  }
}

.model-custom-text {
  text-align: center;
  color: #c9ccd9;
  font-weight: 500;
  font-size: 13px;
  // line-height: 19px;
  letter-spacing: 0.5px;
}

.error-message {
  // color: #eb5757;
  font-size: 14px;
  color: #bf4e4e;
  text-align: center;
  font-weight: 600;
  letter-spacing: 0.5px !important;
  // margin-bottom: 3px;
  margin-top: 10px;
}

.modal-input-box {
 
    background: url(../../../../images/logo/crown.svg) no-repeat scroll 12px 12px;

  margin: 20px 0px;
  display: flex;
  justify-content: center;
  position: relative;
  flex-direction: column;
  align-items: center;
  .modal-input-amt {
    width: 301px;
    height: 42px;
    text-align: center;
    background-color: #242129;
    border: 2px solid var(--primary-color);
    font-weight: 700;
    font-size: 16px;
    color: #e5e8eb;
    &:focus {
      outline: none;
    }
  }
  .disable-deposit-withdraw-box {
    cursor: not-allowed;
  }
  .modal-image-crown {
    position: absolute;
    top: 13px;
    left: 15px;
  }
}

.modal-available-crown {
  .available-text {
    // color: #079b9d;
    color: var(--primary-color);
    text-align: center;
    font-weight: 600;
    letter-spacing: 0.5px !important;
    margin-bottom: 18px;
  }
}



@include max-break-point(mobile-phone) {
  .modal-wrapper {
    width: 360px !important;
  }
}
