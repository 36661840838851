//forex live soon css
.forexLiveSoon-wrapper {
  background-image: url('../../../images/extra/forex.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  backdrop-filter: blur(24px);

  @include max-break-point(tablet-landscape) {
    background-image: none;
    backdrop-filter: blur(24px);
  }

  .forex-live-text-div {
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;

    @media screen and (max-width: 600px) {
      height: 60vh !important;
    }
    @media screen and (max-width: 400px) {
      height: 70vh !important;
    }
    .forex-live-soon-text {
      font-family: 'Cinzel', serif;
      color: #aa9b46;
      font-weight: 5;
      font-size: 30px;
      line-height: 110%;
      text-transform: uppercase;
    }
    .forex-live-soon-redirect {
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 600;
      cursor: pointer;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.004em;
      color: #068385;
    }
  }
 
  .forex-upper-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 6px;
  }

  .forex-live-form-container {
    margin-top: 10px;
  }

  .forex-form-group {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: 8px;
    margin-bottom: 25px;
    position: relative;



    p {
      margin-bottom: 6px;
    }

    input {
      outline: none;
      background: none;
      width: 100%;
      height: 30px;
      color: #fff;
      padding: 2px 10px;
      padding-right: 26px;
      border: 1px solid #fff;
      height: 42px;
      border-radius: 10px;

      &::placeholder {
        color: #ffffffbf;
      }
    }

    .forex-input-addonIcon {
      position: absolute;
      right: 4px;
      bottom: 8px;
      z-index: 2;

      button {
        cursor: pointer;
        background: none;
        outline: none;
        border: none;
      }
    }

    .forex-error-msg {
      font-size: 12px;
      font-style: italic;
      color: #db1818;
      font-weight: 400;
    }
    
  }
}


.forex-fail-text {
  font-size: 16px;
  font-style: normal;
  color: #db1818;
  font-weight: 500;
  margin-top: 8px;
  text-align: center;
}

.bridge-disclaimer-text {
  margin-top: 20px;
  text-align: center;

  p {
    font-style: italic;
    color: #ffffffab;
    font-size: 14px;
    font-weight: 300;

    strong {
      font-weight: 600;
    }
  }
}

@include max-break-point(tablet-landscape) {
  .forex-live-text-div {
    height: 45vh !important;
  }
}

@include max-break-point(tablet) {
  .forex-live-text-div {
    height: 35vh !important;
  }
}
@include max-break-point(mobile-phone) {
  .forex-live-text-div {
    height: 25vh !important;
  }
  .forex-live-soon-text {
    font-size: 16px !important;
  }
  .forex-live-soon-redirect {
    font-size: 10px !important;
    line-height: 16px;
  }
}
