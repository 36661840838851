.main-decor {
  border: 2px solid var(--primary-color);
  position: relative;

  .decor-edge-tl,
  .decor-edge-tr,
  .decor-edge-bl,
  .decor-edge-br {
    position: absolute;
    padding: 4px;
    @include disable-selection;
  }

  .decor-edge-tl {
    top: 0;
    left: 0;
  }

  .decor-edge-tr {
    top: 0;
    right: 0;
    transform: rotate(90deg);
  }

  .decor-edge-bl {
    bottom: 0;
    left: 0;
    transform: rotate(270deg);
  }

  .decor-edge-br {
    bottom: 0;
    right: 0;
    transform: rotate(180deg);
  }

  .decor-content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 25px 50px;
  }
}
